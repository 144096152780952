import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";



// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  mobileOpen: boolean;
  errormsg: string;
  apiImage:File|null;
  roledata: string | undefined;
  editToggel:boolean;
  catalougeData:any,
  lawFirmData:boolean,
  confirmdelete:boolean,
  changeimage: string,
  anchorEl:any,
  open:boolean,
  dataArray: { email: string }[],
  showEmail:boolean,
  openDialog:boolean,
  serviceName:string,
  serviceID:number,
  savechanges:{firm:boolean,website:boolean},
  userMailId:string,
  EmailErrorMsg:boolean,
  popUpShoww:boolean,
  ShowResponsee:{
    error:string,
    success:string,
  },
  LawfirmNav:boolean,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ServicesManagementController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getServicesManagementFirmApiCallId:string = "";
  removeServicesManagementFirmApiCallId:string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      arrayHolder: [],
      token: "",
      serviceID:0,
      mobileOpen: false,
      apiImage:null,
      errormsg: "",
      roledata: "",
      editToggel:false,
      catalougeData:[],
     lawFirmData:false,
     changeimage:'',
     open:false,
     dataArray: [],
     savechanges:{firm:false,website:false},
     showEmail:false,
     openDialog:false,
     confirmdelete:false,
     serviceName:"",
     userMailId:"",
     anchorEl:null,
     EmailErrorMsg:false,
     popUpShoww:false,
     ShowResponsee:{
      error:"",
      success:"", 
    },
    LawfirmNav:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getServicesManagement()
    // Customizable Area End
  }

  // Customizable Area Start
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: true });
  }

  handleClose1 =()=>{
    this.setState({anchorEl:null,serviceName:"",serviceID:0})
  }
  handleClick2 = (event:any,name:string,id:any)=>{
    this.setState({anchorEl:event.currentTarget,serviceName:name,serviceID:id})
  }

  handleclosesidebar = () => {
    this.setState({ mobileOpen: false });
  }
  handleDashboard = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "DashBoard");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  handleconfirmdelete = ()=>{
    this.setState({confirmdelete:false})
  }
  handleconfirmdeleteOk = ()=>{
    this.setState({confirmdelete:false})
    this.deleteServiceManagement()
  }
  handleconfirmdeleteopen = ()=>{
    this.setState({confirmdelete:true, anchorEl:null})
  }
  handleAddservices = () =>{
    setStorageData('title', 'Add New Service')
    setStorageData('isAdd',true)
    this.handleAddEditServices()
  }
  handleEditServices = (id:any)=>{
    setStorageData('title', 'Edit Service')
    setStorageData('isAdd',false)
    setStorageData('serviceId',id)
    this.handleAddEditServices()
  }
  handleLawFirmManagement = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "lawfirmManagement");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  handleAddEditServices = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));  message.addData(getName(MessageEnum.NavigationTargetMessage), "AddEditServices");  message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  determineMainToken = (  metaSignUpToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } },  metaLoginToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } }): { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } } | null => {
    if (metaSignUpToken?.data?.id) {
      if (metaLoginToken?.id 
        && metaSignUpToken.data.id !== metaLoginToken.id) {  return metaLoginToken;  }  return metaSignUpToken;
    }
   if (metaLoginToken?.id) {
      return metaLoginToken;
    }

    return null;
  };
 


  getServicesManagement = async () => {
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken(metaSignUpToken, metaLoginToken);
    const header = {
      "Content-Type": "application/json",
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getServicesManagementFirmApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_catalogue/catalogues/lists_of_services"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteServiceManagement = async () => {
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken(metaSignUpToken, metaLoginToken);
    const header = {
      "Content-Type": "application/json",
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.removeServicesManagementFirmApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_productdescription/service_managements/remove_service?service_id=${this.state.serviceID}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDelete
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ roledata: maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token });
  };


  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getServicesManagementFirmApiCallId) {

      if (responseJson?.errors) {
      this.setState({popUpShoww:true,ShowResponsee:{error:responseJson.errors[0]?.token,success:""}})

      }

      else {
        this.setState({ catalougeData: responseJson?.data})
      }
    }else if(apiRequestCallId ===this.removeServicesManagementFirmApiCallId){
        if (responseJson?.errors) {
            this.setState({popUpShoww:true,ShowResponsee:{error:responseJson.errors[0]?.token,success:""}})
            }
      
            else {
              this.setState({ popUpShoww:true,ShowResponsee:{success:responseJson?.message,error:''}},()=>this.getServicesManagement())
            }
            this.setState({serviceID:0,serviceName:'',anchorEl:null,confirmdelete:false})
    }
    // Customizable Area End
  }

  // Customizable Area End
}
