import React from "react";
// Customizable Area Start
import { Box, Button, Checkbox, Grid, List, ListItem, ListItemText, Typography, styled } from "@material-ui/core";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardOutlinedIcon from '@material-ui/icons/ArrowUpwardOutlined';
import { iconsetting ,dollericon} from "./assets";
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import FolderOpenOutlinedIcon from '@material-ui/icons/FolderOpenOutlined';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import CalendarTodayRoundedIcon from '@material-ui/icons/CalendarTodayRounded';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
// Customizable Area End
import LawyerDashboardController, {
  Props
} from "./DashboardController";

export default class LawyerDashboard extends LawyerDashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start


    // Customizable Area End
    return (
         // Customizable Area Start
        <MainContainer data-test-id="LawyerDashboarddMain">
            <Grid container spacing={0}>
                <Grid item lg={2} md={3} sm={1} xs={1} >
                    <NavigationMenu navigation={this.props.navigation} id={""} />
                </Grid>
                <Grid item lg={10} md={9} sm={11} xs={11} style={{ padding: '30px', height: '100vh', overflowY: 'scroll', overflowX: 'hidden' }}>
                    <Box className="titleBox">
                        <Typography className="titleTxt" data-test-id="title">Dashboard</Typography>
                        <Box className="notificationbox" style={{marginBottom:'15px'}}>
                            <NotificationsOutlinedIcon className="notificationIcon" />
                            <FiberManualRecordIcon className="dotIcon" />
                            <Button className="browseBtn">Browse Tasks</Button>
                        </Box>
                    </Box>
                    <Box style={{marginBottom:'30px'}}>
                        <Grid container spacing={1}>
                            <Grid item lg={7} md={12} sm={12} xs={12} >
                                <Box className="ServiceReqBox">
                                    <Box className="titleBox" style={{ marginBottom: '20px' }}>
                                        <Typography style={{ display: 'flex', marginLeft: '7px' }}>
                                            <HelpOutlineIcon className="helpIcon" />
                                            <Typography className="serviceTxt">Service Request</Typography>
                                        </Typography>
                                        <MoreVertIcon style={{ marginTop: '12px' }} />
                                    </Box>
                                    {this.state.DetailsUser &&
                                        this.state.DetailsUser.map((e, index) => (
                                            <Box key={index} className="detailServiceBox" style={{ borderBottom: e.Title !== "Dominic Stuart sent you an enquiry" ? '1px solid #E2E8F0' : 'none' }}>
                                                <Box className="imageContent">
                                                    <Box className="imageServiceBox">
                                                        <img src={e.Imag} className="imageBox" />
                                                        <Box style={{ marginLeft: '10px', maxWidth: '270px' }}>
                                                            <Typography className="userName">{e.Title}</Typography>
                                                            <Typography className="userContent">{e.Data}</Typography>
                                                        </Box>
                                                    </Box>
                                                    <Box className="btnServiceBox">
                                                        <Button variant="contained" className="Acceptbtn">Accept</Button>
                                                        <Button variant="outlined" className="Rejectbtn">Reject</Button>
                                                    </Box>
                                                </Box>
                                                <Box className="iconServiceBox">
                                                    <MoreVertIcon className="icontxt" />
                                                    <Typography className="threehors">3h</Typography>
                                                </Box>
                                            </Box>
                                        ))
                                    }
                                </Box>
                            </Grid>
                            <Grid item lg={5} md={12} sm={12} xs={12} >
                                <Box style={{ display: 'flex' }}>
                                    <Box style={{ display: 'flex', marginTop: '10px' }}>
                                        <Box className="timesheetbox">
                                            <Box style={{ display: 'flex', width: '220px' }}>
                                                <img src={dollericon} style={{ marginRight: '8px', width: '36px', height: '36px' }} />
                                                <Typography className="dollertxt" style={{ marginTop: '4px' }}> Total Dollars</Typography>
                                            </Box>
                                            <Typography className="hourstxt">$ <span style={{ fontFamily: 'DM Sans', color: '#000000', fontSize: '22px', fontWeight: 400 }}>5000</span></Typography>
                                            <Box style={{ display: 'flex' }}>
                                                <ArrowUpwardOutlinedIcon style={{ padding: "5px", fontSize: "12px", background: '#D1FAE5', color: '#059669', borderRadius: '60px' }} />
                                                <Typography style={{ marginLeft: '10px', marginTop: '2px', fontFamily: 'Inter', fontSize: '12px', fontWeight: 700, color: '#059669' }}>+17.06%  </Typography>
                                            </Box>
                                        </Box>
                                        <Box className="timesheetbox" style={{ marginLeft: '12px' }}>
                                            <Box style={{ display: 'flex', width: '220px' }}>
                                                <img src={iconsetting} style={{ marginRight: '8px', width: '36px', height: '36px' }} />
                                                <Typography className="dollertxt"> Total Working Hours</Typography>
                                            </Box>
                                            <Typography className="hourstxt">560 <span style={{ fontFamily: 'DM Sans', color: '#8C8C8C' }}>hours</span></Typography>
                                            <Box style={{ display: 'flex' }}>
                                                <ArrowDownwardIcon style={{ padding: "5px", fontSize: "12px", background: '#FEE2E2', color: '#DC2626', borderRadius: '60px' }} />
                                                <Typography style={{ marginLeft: '10px', marginTop: '2px', fontFamily: 'Inter', fontSize: '12px', fontWeight: 700, color: '#DC2626' }}>-17.06% from last month </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className="urgentbox">
                                    <Box>
                                        <Typography style={{ fontFamily: 'Inter', fontWeight: 700, fontSize: '16px', color: "#0F172A", }}>Urgent Deadlines</Typography>
                                        <Box style={{ display: 'flex', justifyContent: 'center', margin: '10px 0px' }}>
                                            <FiberManualRecordIcon style={{ color: '#F87171', fontSize: '15px' }} />
                                            <Typography style={{ fontFamily: 'DM Sans', fontWeight: 400, fontSize: '11px', color: "#0F172A", marginLeft: '5px' }}>
                                                Case 256 will overdue at
                                                <span style={{ color: '#F87171' }}> May 4 2024</span>
                                            </Typography>
                                        </Box>
                                        <Box style={{ display: 'flex', justifyContent: 'center', margin: '10px 0px' }}>
                                            <FiberManualRecordIcon style={{ color: '#F87171', fontSize: '15px' }} />
                                            <Typography style={{ fontFamily: 'DM Sans', fontWeight: 400, fontSize: '11px', color: "#0F172A", marginLeft: '5px' }}>
                                                Case 256 will overdue at
                                                <span style={{ color: '#F87171' }}> May 4 2024</span>
                                            </Typography>
                                        </Box>
                                        <Box style={{ display: 'flex', justifyContent: 'center', margin: '10px 0px' }}>
                                            <FiberManualRecordIcon style={{ color: '#34D399', fontSize: '15px', marginLeft: '4px' }} />
                                            <Typography style={{ fontFamily: 'DM Sans', fontWeight: 400, fontSize: '11px', color: "#0F172A", marginLeft: '5px' }}>
                                                Hellen wiliam Paid her overdue invoice 
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Typography className="negationicon">!</Typography>
                                    </Box>
                                </Box>
                                <Box className="timesheetbox1">
                                    <Box style={{ display: "flex" }}>
                                        <SettingsOutlinedIcon style={{ padding: '5px', borderRadius: '50%', background: '#355EE7', color: "#fff" }} />
                                        <Typography className="Actxt">Account Management</Typography>
                                    </Box>
                                    <List className="MainListBoxss">
                                        <ListItem className="listboxss" onClick={this.handleLawyer}>
                                            <PermIdentityOutlinedIcon className="icondrawer2ss" />
                                            <ListItemText className="listtiless" data-test-id="Setting">
                                                Lawyer Management
                                            </ListItemText>
                                            <ChevronRightOutlinedIcon style={{ color: '#595959' }} />
                                        </ListItem>
                                    </List>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box className="mainTaskBox" >
                        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Box style={{ display: 'flex' }}>
                                <FolderOpenOutlinedIcon className="foldericon" />
                                <Typography className="tasktitletxt">Tasks</Typography>
                            </Box>
                            <AddOutlinedIcon className="iconplus" />
                        </Box>
                        <Box>
                            <Grid container spacing={0}>
                                {this.state.TaskAll &&
                                    this.state.TaskAll.map((e, index) => (
                                        <Grid item lg={3} md={4} sm={6} xs={12}>
                                            <Box style={{ padding: '10px' }} key={index}>
                                                <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <Box style={{ display: 'flex' }}>
                                                        <FiberManualRecordIcon style={{ color: `${e.dotColor}`, fontSize: '17px', marginTop: '3px', marginRight: '5px' }} />
                                                        <Typography className="progrestxt">{e.title} <span style={{ color: '#8C8C8C' }}>{e.spantext}</span></Typography>
                                                    </Box>
                                                    <MoreVertIcon style={{ color: '#434343' }} />
                                                </Box>
                                                <Box className="boxtaskbox" style={{ borderLeft: `4px solid ${e.dotColor}`, boxShadow: '2px 3px 6px 3px #0000000F' }}>
                                                    <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <Typography className="Tasknametxt">Task name</Typography>
                                                        <Typography className="hightxt" style={{ background: `${e.backgroundData}`, color: `${e.colordata}` }}>{e.data}</Typography>
                                                    </Box>
                                                    <Typography className="HellenTxt">Hellen Whilliams</Typography>
                                                    <Box className="calendarBox">
                                                        <CalendarTodayRoundedIcon className="calendricon" />
                                                        <Typography className="calndrtxt">2 Jan - 15 Mar 2024</Typography>
                                                    </Box>
                                                    <Box style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0px 5px 0px' }}>
                                                        <Box style={{ display: 'flex' }}>
                                                            <Checkbox defaultChecked={e.checkedFirst} className="chckboxicon" icon={<RadioButtonUncheckedOutlinedIcon style={{ background: '#fff', border: '2px solid ##CBD5E1', fontSize: '19px' }} />}
                                                                checkedIcon={<CheckOutlinedIcon style={{ color: '#fff', backgroundColor: `${e.dotColor}`, borderRadius: '50%', fontSize: '19px' }} />} />
                                                            <Typography className="todotxt">To-do list item</Typography>
                                                        </Box>
                                                        <MoreVertIcon style={{ color: '#595959', fontSize: '18px', marginTop: '3px' }} />
                                                    </Box>
                                                    <Box style={{ display: 'flex', justifyContent: 'space-between', margin: '5px 0px' }}>
                                                        <Box style={{ display: 'flex' }}>
                                                            <Checkbox defaultChecked={e.checkedSecond} className="chckboxicon1" icon={<RadioButtonUncheckedOutlinedIcon style={{ background: '#fff', border: '2px solid ##CBD5E1', fontSize: '19px' }} />}
                                                                checkedIcon={<CheckOutlinedIcon style={{ color: '#fff', backgroundColor: `${e.dotColor}`, borderRadius: '50%', fontSize: '19px' }} />} />
                                                            <Typography className="todotxt1">To-do list item</Typography>
                                                        </Box>
                                                        <MoreVertIcon style={{ color: '#595959', fontSize: '18px', marginTop: '3px' }} />
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </MainContainer>
       
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const MainContainer = styled('div')({
    "& .titleBox": {
        display: 'flex',
        justifyContent: 'space-between',
    },
    "& .titleTxt": {
        fontSize: '32px',
        fontFamily: 'DM Sans',
        fontWeight: 700,
        color: '#000000',
        position: 'relative'
    },
    "& .notificationbox": {
        position: 'relative',
        display:'flex'
    },
    "& .notificationIcon": {
        padding: '10px',
        border: '1px solid #C3D1FF',
        borderRadius: '8px',
        color: '#355EE7',
    },
    "& .dotIcon": {
        position: 'absolute',
        top: '-3px',
        left: '38px',
        color: '#355EE7',
        fontSize: '14px'
    },
    "& .browseBtn":{
        background:'#355EE7',
        padding:'10px 16px',
        marginLeft:'20px',
        color:"#fff",
        borderRadius:'8px',
        textTransform:'none',
        fontSize:'16px',
        fontWeight:400,
        fontFamily:'DM Sans'
    },
    "& .ServiceReqBox": {
        boxShadow: "2px 3px 6px 3px #0000000F",
        padding: '20px 20px 13px 20px',
        borderRadius: '16px',
        marginTop:"10px"
    },
    "& .serviceTxt": {
        fontSize: '16px',
        fontFamily: 'DM Sans',
        fontWeight: 400,
        color: '#0F172A',
        marginLeft: '10px',
        marginTop: '7px'
    },
    "& .helpIcon": {
        background: '#4872FF',
        color: '#FFFFFF',
        padding: '7px',
        borderRadius: '50%'
    },
    "& .detailServiceBox": {
        display: 'flex',
        padding: '16px 8px',
        justifyContent: 'space-between'
    },
    "& .imageServiceBox": {
        display: "flex",
    },
    "& .imageContent": {
        display: 'flex',
        justifyContent: 'space-between',
        marginRight: '10px',
        "@media (max-width: 1100px)": {
            display: 'block !important',
        }
    },
    "& .imageBox": {
        width: '44px',
        height: '44px',
        borderRadius: '50%',
        border: '1px solid #F0E5FF',
        marginRight: '10px',
    },
    "& .userName": {
        fontFamily: 'DM Sans',
        fontSize: '14px',
        fontWeight: 700,
        color: '#434343',
        wordWrap: 'normal',
    },
    "& .userContent": {
        fontFamily: 'DM Sans',
        fontSize: '14px',
        fontWeight: 400,
        color: '#595959',
        wordWrap: 'normal',
    },
    "& .btnServiceBox": {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
        marginLeft: '70px',
        "@media (max-width: 1100px)": {
            marginLeft: '65px'
        }
    },
    "& .iconServiceBox": {
        marginLeft: '20px'
    },
    "& .threehors": {
        color: '#8C8C8C',
        fontFamily: 'DM Sans',
        fontSize: '14px',
        fontWeight: 400,
    },
    "& .Acceptbtn": {
        fontFamily: 'DM Sans',
        fontSize: '16px',
        fontWeight: 400,
        color: '#FFFFFF',
        background: "#355EE7",
        padding: '6px 10px',
        textTransform: 'none',
        borderRadius: '8px',
        height: '30px'
    },
    "& .Rejectbtn": {
        marginLeft: '15px',
        fontFamily: 'DM Sans',
        fontSize: '16px',
        fontWeight: 400,
        color: '#DC2626',
        background: "#fff",
        padding: '6px 10px',
        textTransform: 'none',
        border: '1px solid #F87171',
        borderRadius: '8px',
        height: '32px'
    },
    "& .icontxt": {
        marginBottom: '20px',
        color: '#595959'
    },
    "& .hourstxt": {
        fontFamily: 'Inter',
        fontWeight: 400,
        fontSize: '16px',
        color: '#434343',
        margin: '3px 0px'
    },
    "& .dollertxt": {
        fontFamily: 'DM Sans',
        fontSize: '16px',
        fontWeight: 400,
        color: '#0F172A'
    },
    "& .timesheetbox": {
        background: "#FFFFFF",
        padding: '8px 12px',
        boxShadow: "2px 3px 6px 3px #0000000F",
        borderRadius: '16px',
        maxWidth: '234px'
    },
    "& .timesheetbox1": {
        background: "#FFFFFF",
        padding: '8px 5px',
        boxShadow: "2px 3px 6px 3px #0000000F",
        borderRadius: '16px',
    },
    "& .Actxt": {
        fontFamily: 'DM Sans',
        fontSize: '16px',
        fontWeight: 400,
        color: '#0F172A',
        marginLeft: '10px',
        marginTop: '4px',
        marginBottom: '16px'
    },
    "& .MainListBoxss": {
        padding: '1px 5px',
    },
    "& .listboxss": {
        padding: '5px !important',
        height: '56px',
        borderRadius: '8px',
        "&:hover": {
            backgroundColor: "#ECF1FF",
            color: "#355EE7",
            "& .icondrawer2":
            {
                color: "#355EE7",
            },
        },
    },
    "& .icondrawer2ss": {
        marginLeft: '5px',
        marginBottom: '2px',
        marginRight: '8px',
    },
    "& .listtiless":
    {
        fontSize: "14px !important",
        fontFamily: 'DM Sans',
        fontWeight: 400,
        cursor: 'pointer',
        marginLeft: '5px',
        whiteSpace: 'nowrap'
    },
    "& .negationicon": {
        width: "32px",
        height: '32px',
        background: '#FEE2E2',
        color: '#DC2626',
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 700,
        textAlign: "center",
        borderRadius: "50%",
        padding: '8px 3px 0px 4px'
    },
    "& .urgentbox": {
        display: 'flex',
        justifyContent: 'space-between',
        borderLeft: '4px solid #DC2626',
        borderRadius: '6px',
        padding: '10px 12px',
        margin: '10px 0px',
        boxShadow: "2px 3px 6px 3px #0000000F",
    },
    "& .mainTaskBox": {
        padding: '20px 30px',
        boxShadow: "0px 2px 1px 4px #0000000F",
        borderRadius: '6px',
    },
    "& .tasktitletxt": {
        fontSize: "16px !important",
        fontFamily: 'DM Sans',
        fontWeight: 400,
        color: '#0F172A',
        marginTop: '7px'
    },
    "& .foldericon": {
        padding: '7px',
        borderRadius: '50%',
        color: '#fff',
        background: '#355EE7',
        marginRight: '10px'
    },
    "& .iconplus": {
        color: '#595959',
        padding: '10px',
        borderRadius: '8px'
    },
    "& .progrestxt": {
        fontSize: "16px !important",
        fontFamily: 'DM Sans',
        fontWeight: 400,
        color: '#000',
    },
    "& .boxtaskbox": {
        borderRadius: '6px',
        padding: '16px 12px 10px 20px',
        marginTop: '10px',
    },
    "& .Tasknametxt": {
        fontSize: "16px !important",
        fontFamily: 'DM Sans',
        fontWeight: 700,
        color: '#0F172A',
    },
    "& .hightxt": {
        fontSize: "11px !important",
        fontFamily: 'DM Sans',
        fontWeight: 400,
        padding: '2px 6px',
        borderRadius: '4px'
    },
    "& .HellenTxt": {
        fontSize: "14px",
        fontFamily: 'Inter',
        fontWeight: 400,
        color: '#0F172A',
        margin: '10px 0px'
    },
    "& .calendarBox": {
        padding: '3px 4px',
        border: '1px solid #BFBFBF',
        borderRadius: '4px',
        display: 'flex',
        width: 'fit-content'
    },
    "& .calendricon": {
        color: '#595959',
        marginRight: '10px',
        fontSize: '14px'
    },
    "& .calndrtxt": {
        fontSize: "11px",
        fontFamily: 'DM Sans',
        fontWeight: 400,
        color: '#0F172A',
    },
    "& .todotxt": {
        fontSize: "14px",
        fontFamily: 'DM Sans',
        fontWeight: 400,
        color: '#595959',
    },
    "& .todotxt1": {
        fontSize: "14px",
        fontFamily: 'DM Sans',
        fontWeight: 400,
        color: '#595959',
    },
    "& .chckboxicon": {
        marginTop: '-8px',
        marginLeft: '-10px'
    },
    "& .chckboxicon1": {
        marginTop: '-8px',
        marginLeft: '-10px'
    },
    "& .chckboxicon2": {},
    "& .chckboxicon23": {},
    "& .chckboxicon3": {},
    "& .chckboxicon4": {},


})
// Customizable Area End
