import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";



// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  mobileOpen: boolean;
  errormsg: string;
  apiImage:File|null;
  roledata: string | undefined;
  editToggel:boolean;
  catalougeData:{
    "data":{
      id:string,
      "attributes":{
        website:string,
          firm:string,
          email:string,
          user_role:string,
          profile_image:string
      }
    }
  },
  lawFirmData:boolean,
  changeimage: string,
  open:boolean,
  dataArray: { email: string }[],
  showEmail:boolean,
  openDialog:boolean,
  savechanges:{firm:boolean,website:boolean},
  userMailId:string,
  EmailErrorMsg:boolean,
  popUpShoww:boolean,
  ShowResponsee:{
    error:string,
    success:string,
  },
  LawfirmNav:boolean,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CatalogueController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getProductApiCallId: any;
  UpdateLawFirmApiCallId:string = "";
  SendInviteLinkApiCallId:string = "";
  getUserProfileApiCallId:string="";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      arrayHolder: [],
      token: "",
      mobileOpen: false,
      apiImage:null,
      errormsg: "",
      roledata: "",
      editToggel:false,
      catalougeData:{
        "data":{
          id:'',
          "attributes":{
            website:"",
              firm:"",
              email:"",
              user_role:"",
              profile_image:""
          }
        }
      },
     lawFirmData:false,
     changeimage:'',
     open:false,
     dataArray: [],
     savechanges:{firm:false,website:false},
     showEmail:false,
     openDialog:false,
     userMailId:"",
     EmailErrorMsg:false,
     popUpShoww:false,
     ShowResponsee:{
      error:"",
      success:"", 
    },
    LawfirmNav:false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getUserProfile()
    
    // Customizable Area End
  }

  // Customizable Area Start


  handleClick = () => {
    this.setState({open:true});
  };

  handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    this.setState({open:false})
  };

  handleClosePopUp = () => {
    this.setState({ openDialog: false });
  };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: true });
  }

  handleclosesidebar = () => {
    this.setState({ mobileOpen: false });
  }
  handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    
    const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/svg+xml']; 
  
    if (files && files[0] && allowedTypes.includes(files[0].type)) {
      this.setState({ changeimage: URL.createObjectURL(files[0]), apiImage: files[0] });
    } 
  };

  handleInviteSubmit = (event: { email: string, role: string }) => {
    if (event.role) {
      setStorageData("roleName", JSON.stringify(event.role))
    }
  }
  handleErrorDiv = (a: boolean, b: any, c: string) => {
    return a ? b : c;
  }
  handleDashboard = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "DashBoard");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  handleLawFirmManagement = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "lawfirmManagement");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  handleServicesManagement = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ServicesManagement");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  determineMainToken = (
    metaSignUpToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } },
    metaLoginToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } }
  ): { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } } | null => {
    if (metaSignUpToken?.data?.id) {
      if (metaLoginToken?.id && metaSignUpToken.data.id !== metaLoginToken.id) {
        return metaLoginToken;
      }
      return metaSignUpToken;
    }

    if (metaLoginToken?.id) {
      return metaLoginToken;
    }

    return null;
  };
  getUserProfileAsync=(responseJson:any)=>{
    if (responseJson?.errors) {
      this.setState({popUpShoww:true,ShowResponsee:{error:responseJson.errors,success:""}})
    }
    else {
      this.setState({userMailId:responseJson?.user?.data.attributes.email ,catalougeData: responseJson?.user, lawFirmData: true,changeimage:responseJson?.user?.data?.attributes?.profile_image?.url})
    }
  }

  getUserProfile = async () => {
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken(metaSignUpToken, metaLoginToken);

    const header: { "Content-Type": string; token: string | undefined } = {
      "Content-Type": "application/json",
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getUserProfileApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getUserDataEndPoint}${maintoken?.id || maintoken?.data?.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({roledata:maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token})
  };

 

  UpdateUserData = async (values: {
    firmName: string,
    Website: string,
  }) => {
    const signupToken: string = await getStorageData("userdetails");
    const loginToken: string = await getStorageData("token");
    const metaSignUpTokens = JSON.parse(signupToken);
    const metaLoginTokens = JSON.parse(loginToken);

    const maintokens = this.determineMainToken(metaSignUpTokens, metaLoginTokens);
    const header = {
      "token": maintokens?.serialized_data?.meta?.token || maintokens?.meta?.token
    };
    const formData = new FormData();
      formData.append("firm", values.firmName);
      formData.append("website", values.Website);
      if(this.state.apiImage){
        formData.append("image",this.state.apiImage)
      }
    


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    this.UpdateLawFirmApiCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updatLawfirmEndPoint}${this.state.catalougeData.data.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeUpdate
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  inviteLinkSend = async (values: {
    role: string
    email: string,
  }) => {
    if(values.email){
      this.setState((prevState) => ({
        dataArray: [
          ...prevState.dataArray,
          { email: values.email }
        ]
      }));
    }

    const header = {
      "Content-Type": "application/json",
      "Authorization":"pat-na1-12f02bab-f25f-4779-8579-2f9e3a1714d5",
      "Token":this.state.roledata
    };

    const httpBody = {
      "email": values.email,
      "role": values.role,
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );



    this.SendInviteLinkApiCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.inviteLinkEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAdd
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    
    if (apiRequestCallId === this.UpdateLawFirmApiCallId) {
      if(responseJson?.message){
        this.setState({ editToggel: !this.state.editToggel,LawfirmNav:true ,popUpShoww:true,ShowResponsee:{error:"",success:"Lawfirm details updated successfully"}})
        setTimeout(() => {
          this.getUserProfile()
          this.handleLawFirmManagement()
        },500)
      }
      else if(responseJson?.errors[0]){
        this.setState({popUpShoww:true,ShowResponsee:{error:responseJson.errors[0],success:""}})
      }
       
    }
    if (apiRequestCallId === this.SendInviteLinkApiCallId) {

      if (responseJson?.errors) {
        this.setState({popUpShoww:true,ShowResponsee:{error:responseJson.errors,success:""}})
      }
      else {
        this.setState({showEmail:true,openDialog:true})
      }
    }
    if (apiRequestCallId === this.getUserProfileApiCallId) {

     this.getUserProfileAsync(responseJson)
    }
    // Customizable Area End
  }

  // Customizable Area End
}
