Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.updatLawfirmEndPoint = "/account_block/accounts/";
exports.apiMethodTypeUpdate = "PUT";
exports.apiMethodTypeAdd = "POST";
exports.apiMethodTypeDelete = "DELETE";
exports.inviteLinkEndPoint ="bx_block_email_notifications/email_notifications/send_signup_invite";
exports.getApiMethod = 'GET';
exports.getUserDataEndPoint ="account_block/accounts/"

// Customizable Area End