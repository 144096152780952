import React from "react";

// Customizable Area Start
import { Grid, Button, Typography, Box,  } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {styled} from '@material-ui/styles';
import Checkbox from '@material-ui/core/Checkbox';
import {logo} from './assets'
// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { open, dialogType, checked,
        termsData ,privacyData} = this.state;
    
   return (
    <MainGrid container data-test-id="TermsConditions">
                <Grid item xs={12} className="logoGrid">
                    <img src={logo} alt="Logo" className="logo" />
                </Grid>
                <Grid container item xs={12} justifyContent="center" alignItems="center" className="secondGridItem">
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        p={3}
                        className="formBox"
                    >
                        <Typography variant="h4" gutterBottom className="signupText">
                            Before you start...
                        </Typography>
                        <Box display="flex" alignItems="center" mt={2} mb={2}>
                            <Typography className='acceptText' variant="body2" style={{ display: 'inline-block' }}>
                                <CustomCheckbox
                                    data-test-id="check"
                                    checked={checked}
                                    onChange={this.handleChange}
                                    color="primary"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                />
                                Please accept Digip's{' '}
                                <span data-test-id="terms" onClick={() => this.handleClickOpen('terms')} className="termsSpan">
                                    Terms and Conditions
                                </span>{' '}
                                and{' '}
                                <span data-test-id="privacy" onClick={() => this.handleClickOpen('privacy')} className="privacySpan">
                                    Privacy Policy
                                </span>{' '}
                                to continue.
                            </Typography>
                        </Box>
                        <button data-test-id="continue" onClick={this.handleNavigation} className='continueBtn' color="primary" disabled={!this.state.checked} >
                            Continue
                        </button>
                    </Box>
                </Grid>
                <Dialog PaperProps={{
                    style: {
                        borderRadius: "16px",
                        padding: "20px"
                    },
                }} className='customDialog' onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={open}>
                    <DialogTitle   >
                        <Typography style={{
                        fontSize: "22px",
                        fontWeight: 400,
                        fontFamily: "DM Sans",
                        lineHeight: "28px",
                        color:"black",
                        padding:"10px"
                    }}>{dialogType === 'terms' ? 'Terms and Conditions' : 'Privacy Policy'}</Typography>
                        <IconButton
                            aria-label="close"
                            onClick={this.handleClose}
                            data-test-id="close"
                            style={{
                                position: 'absolute',
                                right: 14,
                                top: 35,
                                color: '#000',
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        {dialogType === 'terms' ? (
                            <><Typography className="dialogText" style={{marginTop:"20px"}} gutterBottom>
               {termsData?.replace(/[\r\n]+/g, '')}
               </Typography></>
                        ) : (
                            <><Typography style={{marginTop:"20px"}}  className="dialogText" gutterBottom>
                 {privacyData?.replace(/[\r\n]+/g, '')}</Typography></>
                            
                        )}
                    </DialogContent>
                    {/* <DialogActions>
                    </DialogActions> */}
                </Dialog>
            </MainGrid>
   )
    // Customizable Area End
  }
}

// Customizable Area Start
const CustomCheckbox = styled(Checkbox)({
  // Set the border radius
  '& .MuiSvgIcon-root': {
      color: '#355EE7', // Set the checked color
      borderRadius: '10px',
      marginLeft:"-10px"
  },
});
const updatedDialog = styled(DialogTitle)({
  "&.MuiTypography-h6": {
      fontSize: "2rem !important"
  }
})
const MainGrid = styled(Grid)({
  height: '100vh',
  position: 'relative',
  "& .logoGrid": {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: "13px",
      color: "#ffff",
      height: '10%',
  },
  "& .logo": {
      width: '100px',
      height: 'auto',
  },
  "& .secondGridItem": {
      height: '90%',
      marginTop: '-5%',
  },
  "& .formBox": {
      width: '100%',
      maxWidth: '400px',
  },
  "& .signupText": {
      width: '90%',
      marginBottom: "30px",
      fontFamily: "DM Sans",
      fontSize: "33px",
      fontWeight: 700,
      lineHeight: "42px",
      marginLeft:"50px"
  },
  "& .continueBtn": {
      width: "100%",
      padding: "20px",
      marginTop: "30px",
      borderRadius: "10px",
      backgroundColor:"#355EE7",
      color:"white",
      textTransform:"capitalize",
      fontSize:"16px",
      fontWeight:400,
      fontFamily:"DM Sans",
      lineHeight:"21px",
      boxShadow:"none !important",
      border:"none",
      cursor:'pointer',
      '&:hover': {
        border:'2px solid #B9C5FF',
        boxShadow:'none !important'
      },
      '&:disabled': {
          backgroundColor: "#D9D9D9",
        border:'2px solid #D9D9D9',
      },
      
  },
  "& .checkbox": {
      borderRadius: "10px"
  },
  "& .acceptText": {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 400,
      fontFamily: "DM Sans"
  },
  "& .customDialog": {
      borderRadius: "16px",
      padding: "30px"
  },
  "& .dialogTitle": {
      fontSize: "22px",
      fontWeight: 400,
      fontFamily: "DM Sans",
      lineHeight: "28px"
  },
  "& .termsSpan":{
    color: 'blue', textDecoration: 'underline', cursor: 'pointer !important' 
  },
  "& .privacySpan":{
    color: 'blue', textDecoration: 'underline', cursor: 'pointer !important'
  },
  "& .dialogText":{
    fontWeight:400,
    fontSize:"16px",
    fontFamily:"DM Sans",
    lineHeight:"20px"
  },
  
  // "& .MuiDialogTitle-root": {
  //     color: 'blue !important',
  //     border: "2px solid red"
  // }
});
// Customizable Area End
