// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import  { CountryData } from "react-phone-input-2";


export interface CountryDatatype {
  id: string;
  type: string;
  attributes: {
    name: string;
  };
}

export interface ExperienceDataType{
  name:string
}
export interface ServicesDataType{
  id:string,
  legal_service_name: string,
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  mobileOpen: boolean;
  errormsg: string;
  apiImage:File|null;
  editToggel:boolean;

  changeimage: string,
  openDialog:boolean,
  savechanges:{firm:boolean,website:boolean},
  userMailId:string,
  EmailErrorMsg:boolean,
  userdata: {
    first_name: string;
    last_name: string;
    email: string;
    country_code: string;
    jurisdiction: string;
    firm: string;
    full_phone_number: string;
    profile_image: string;
    legel_service_type_id:string;
    years_of_experience:string;
    accomplishments:string;
  };
  lawfirmNameDisabled:boolean ;
  loadData: boolean;
  userDetails: string;
  countryData:CountryDatatype[],
  experienceData:ExperienceDataType[],
  servicesData:ServicesDataType[],
  prevCountry:string,
  showResponse:{
    error:string,
    success:string
  },
  token: string;
  categoriesArray: any;
  category: string;
  subCategory: string;
  isVisible: boolean;
  dropdownCategoryStatus: boolean;
  activeModalType: string;
  selectedCategoryID: any;
  


  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CategoriessubcategoriesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
 
  getUserProfileApiCallId:string="";
  getCountryApiCallId:string = "";
  getExperienceApiCallId:string = "";
  getServiceApiCallId:string = "";
  UpdateUserDetailsApiCallId:string ="";
  
getCategoriesApiCallId: any;
deleteCategoriesApiCallId: any;
deleteSubCategoriesApiCallId: any;
addCategoryApiCallId: any;
addSubCategoryApiCallId: any;


  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      mobileOpen: false,
      apiImage:null,
      errormsg: "",
      editToggel:false,
     changeimage:'',
     savechanges:{firm:false,website:false},
     openDialog:false,
     userMailId:"",
     EmailErrorMsg:false,
     userdata: {
      first_name: "",
      last_name: "",
      email: "",
      country_code: "",
      full_phone_number: "",
      jurisdiction: "",
      firm: "",
      profile_image:"",
      legel_service_type_id:"",
      years_of_experience:"",
      accomplishments:"",
    },
    lawfirmNameDisabled:false,
    loadData:true,
    userDetails: "",
    countryData:[],
    prevCountry:"in",
    showResponse:{
      error:"",
      success:""
    },
    experienceData:[],
    servicesData:[],

    token: "",
    categoriesArray: [],
    category: "",
    subCategory: "",
    isVisible: false,
    dropdownCategoryStatus: false,
    activeModalType: "",
    selectedCategoryID: []


    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getUserProfile()
    this.getCountryList()
    this.checkLawyer()
    this.getExperienceList()
    this.getServicesList()
  }
  
  
  // Customizable Area End
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

  
    if (apiRequestCallId === this.UpdateUserDetailsApiCallId) {
      this.asyncUpdateUser(responseJson)
    }
    if (apiRequestCallId === this.getCountryApiCallId) {
      this.setState({countryData:responseJson?.data})
     }
     if (apiRequestCallId === this.getExperienceApiCallId) {
      this.setState({experienceData:responseJson})
     }
     if (apiRequestCallId === this.getServiceApiCallId) {
      this.setState({servicesData:responseJson})
     }

    if (apiRequestCallId === this.getUserProfileApiCallId) {
      if (responseJson?.errors) {
        this.setState({openDialog:true,showResponse:{error:responseJson.errors,success:""}})
      }
      else {
        this.setState({userdata: responseJson?.user?.data?.attributes, loadData: false, changeimage:responseJson?.user?.data?.attributes?.profile_image?.url })
      }
    }


    this.handleExtraFunction(message)
    // Customizable Area End
  }

  // Customizable Area Start
  extraFunction=(responseJson1:any,apiRequestCallId:any)=>{
  if (responseJson1.data) {
    if (apiRequestCallId === this.getCategoriesApiCallId) {
      let array = responseJson1.data;
      for (let i = 0; i < array.length; i++) {
        array[i].expand = false;
        array[i].Check = false;
      }
      this.setState({ categoriesArray: array });
    } else if (apiRequestCallId === this.addCategoryApiCallId) {
      this.setState({ isVisible: false, category: "" }, () => {
        this.getCategories();
      });
    } else if (apiRequestCallId === this.addSubCategoryApiCallId) {
      this.setState(
        {
          isVisible: false,
          category: "",
          subCategory: "",
          selectedCategoryID: []
        },
        () => {
          this.getCategories();
        }
      );
    }
    //this.setState({ isVisible: false });
  }
}
extraFunction1=(responseJson1:any,apiRequestCallId:any,errorReponse:any)=>{
  if (
    apiRequestCallId === this.deleteCategoriesApiCallId && !responseJson1.error
  ) {
    this.getCategories();
  }
  else if (apiRequestCallId === this.deleteCategoriesApiCallId && responseJson1.error) {
    this.parseApiCatchErrorResponse(responseJson1.error.message);
  }
  else if (
    apiRequestCallId === this.deleteSubCategoriesApiCallId && !responseJson1.error
  ) {
    this.getCategories();
  }
  else if (
    apiRequestCallId === this.deleteSubCategoriesApiCallId && responseJson1.error
  ) {
    this.parseApiCatchErrorResponse(responseJson1.error.message);
  }
  else if (responseJson1.errors) {
    this.parseApiErrorResponse(responseJson1);
    this.parseApiCatchErrorResponse(errorReponse);
  }
}
  handleExtraFunction=(message:any)=>{
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Recived", message);
      let token = message.getData(getName(MessageEnum.SessionResponseToken));

      this.setState({ token: token }, () => {
        this.getCategories();
      });

    } else
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );

        let responseJson1 = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        let errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        runEngine.debugLog("API Message Recived", message);
        {this.extraFunction(responseJson1,apiRequestCallId)}
          {this.extraFunction1(responseJson1,apiRequestCallId,errorReponse)}
      }
  }

  asyncUpdateUser=(responseJson:any)=>{
    if (responseJson?.message) {
      this.setState({ editToggel: !this.state.editToggel ,openDialog:true,showResponse:{error:"",success:responseJson.message}})
    }
    else if(responseJson?.errors){
      this.setState({openDialog:true,showResponse:{error:responseJson.errors[0]?.message,success:""}})
    }
  }
 
 
  handleClosePopUp = () => {
    this.setState({ openDialog: false });
    if(this.state.showResponse.success!=""){
      this.getUserProfile()
      this.handleDashboard()
    }
  };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: true });
  }

  handleclosesidebar = () => {
    this.setState({ mobileOpen: false });
  }
  handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    
    const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/svg+xml']; 
  
    if (files && files[0] && allowedTypes.includes(files[0].type)) {
      this.setState({ changeimage: URL.createObjectURL(files[0]), apiImage: files[0] });
    } 
  };

  handleDashboard = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "LawyerDashboard");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  
  determineMainToken = (
    metaSignUpToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } },
    metaLoginToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } }
  ): { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } } | null => {
    if (metaSignUpToken?.data?.id) {
      if (metaLoginToken?.id && metaSignUpToken.data.id !== metaLoginToken.id) {
        return metaLoginToken;
      }
      return metaSignUpToken;
    }

    if (metaLoginToken?.id) {
      return metaLoginToken;
    }

    return null;
  };
  getUserProfileAsync=(responseJson:any)=>{
    if (responseJson?.errors) {
      this.setState({openDialog:true,showResponse:{error:responseJson.errors,success:""}})
    }
    else {
      this.setState({userMailId:responseJson?.user?.data?.attributes?.email})
    }
  }
  handleStateChange=(signup:string,login:string)=>{
    this.setState({
      userDetails: signup || login
    })
  }
  checkLawyer = () => {
    if (this.state.userDetails=="lawyer") {
      this.setState({ lawfirmNameDisabled: true })
    }
  }
  handleCountryChange(
    phone: string,
    country: CountryData | {} 
  ): string {
    const { prevCountry } = this.state;
    if ('countryCode' in country && country.countryCode !== prevCountry) {
      
      this.setState({ prevCountry: country.countryCode });
      return "+" + country.dialCode; 
    }
    return phone;
  }

  getUserProfile = async () => {
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken(metaSignUpToken, metaLoginToken);
    const header = {
      "Content-Type": "application/json",
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
    };
    this.handleStateChange(metaSignUpToken?.data?.attributes?.user_role,metaLoginToken?.user_role)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserProfileApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.userDetalsEndPoint}${ maintoken?.id || maintoken?.data?.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  UpdateUserData = async (values:{
    firstname: string,
    lastname: string,
    country: string,
    contactNumber: string,
    email: string,
    firm:string,
    legel_service_type_id:string,
    years_of_experience:string,
    accomplishments:string,
  }) => {

    const signupToken: string = await getStorageData("userdetails");
    const loginToken: string = await getStorageData("token");
    const metaSignUpTokens = JSON.parse(signupToken);
    const metaLoginTokens = JSON.parse(loginToken);

    const maintokens = this.determineMainToken(metaSignUpTokens, metaLoginTokens);
  const header = {
    "token": maintokens?.serialized_data?.meta?.token || maintokens?.meta?.token
  };

  const formData = new FormData();
    formData.append("first_name", values.firstname);
    formData.append("last_name", values.lastname);
    formData.append("jurisdiction", values.country);
    formData.append("full_phone_number",values.contactNumber);
    formData.append("email",values.email);
    formData.append("firm",values.firm);
    formData.append("legel_service_type_id",values.legel_service_type_id);
    formData.append("years_of_experience",values.years_of_experience);
    formData.append("accomplishments",values.accomplishments);

    if (this.state.lawfirmNameDisabled) {
      formData.append("is_shared", "true");
    }
    if(this.state.apiImage){
      formData.append("image",this.state.apiImage)
    }
    else{
      formData.append("image","")
    }
  

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );


  this.UpdateUserDetailsApiCallId = requestMessage.messageId;


  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_categories/categories/update_lawyerfirm_information`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    formData
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.apiMethodTypeAddDetail
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
};

  getCountryList= async () => {
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCountryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts/get_countries"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getExperienceList= async () => {
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getExperienceApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_productdescription/service_managements/year_of_experience"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getServicesList= async () => {
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getServiceApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_productdescription/service_managements/legal_services_list"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  setCategoryTxt = (text: string) => {
    this.setState({ category: text });
  };
  setSubCategoryTxt = (text: string) => {
    this.setState({ subCategory: text });
  };
  clickCategory = (item: any, Index: number) => {
    let array = this.state.categoriesArray;
    let idarray = this.state.selectedCategoryID;
    let index = idarray.indexOf(item.attributes.id);

    if (index > -1) {
      idarray.splice(index, 1);
      array[Index].Check = false;
      this.setState({ categoriesArray: array });
    } else {
      idarray.push(item.attributes.id);
      array[Index].Check = true;
      this.setState({ categoriesArray: array });
      this.setState({ selectedCategoryID: idarray });
    }
  };

  toggleModal = (type: string) => {
    this.setState({ activeModalType: type, isVisible: !this.state.isVisible });
  };

  expandCategoryView = () => {
    this.setState({
      dropdownCategoryStatus: !this.state.dropdownCategoryStatus
    });
  };
  expand = (id: string) => {
    let array = this.state.categoriesArray;
    for (let i = 0; i < array.length; i++) {
      if (array[i].id === id) {
        array[i].expand = !array[i].expand;
      }
    }
    this.setState({ categoriesArray: array });
  };

isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  addCategory = () => {
    if (this.isStringNullOrBlank(this.state.category)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else {
      let data = {
        categories: [{ name: this.state.category }]
      };
      const header = {
        "Content-Type": configJSON.categoryApiContentType,
        token: this.state.token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addCategoryApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.categoryAPIEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };
  addSubCategory = () => {
    if (this.isStringNullOrBlank(this.state.subCategory)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else if (this.state.selectedCategoryID.length === 0) {
      this.showAlert(configJSON.errorTitle, configJSON.errorCategory);
      return false;
    } else {
      let data = {
        sub_category: {
          name: this.state.subCategory
        },
        parent_categories: this.state.selectedCategoryID
      };
      const header = {
        "Content-Type": configJSON.categoryApiContentType,
        token: this.state.token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addSubCategoryApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.subCategoryAPIEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  deleteCategories = (id: number) => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryAPIEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  deleteSubCategories = (id: number) => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteSubCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subCategoryAPIEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getCategories = () => {

    if (!this.state.token) {
      return;
    }
    
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  // Customizable Area End
}
