import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { user2,group,userClient1,userClient2,userClient3,tableUser,Services} from "./assets";

interface Data{
  name: string,
  value: number 
}
interface TableData{
  id: string,
  lawfirm: string,
  task: string,
  amount: string,
  status: string,
  background: string,
  color: string,
  img:string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  Data:Data,
  MainData:Data[],
  COLORS:[string,string,string,string],
  TableData:TableData
  rows:TableData[]
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ClientDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      Data:{
        name:"",
        value:0
      },
      MainData:[],
      COLORS:["","","",""],
      TableData:{
        id: '',
        lawfirm: '',
        task: '',
        amount: '',
        status: '',
        background: '',
        color: '',
        img:''
      },
      rows:[]
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {

    super.componentDidMount();
    this.setState({
      MainData: [
        { name: "Comleted", value: 24 },
        { name: "Active", value: 12 },
        { name: "Overdue", value: 10 },
        { name: "Declined", value: 11 },
      ],
      COLORS: ["#34D399", "#355EE7", "#BFBFBF", "#F87171"],
      rows: [
        {
          id: '#08978',
          lawfirm: 'Basant Osama',
          task: 'Family Law Task',
          amount: '$500',
          status: 'pending',
          background: '#F5F5F5',
          color: '#8C8C8C',
          img: user2
        },
        {
          id: '#08979',
          lawfirm: 'Ali Hassan',
          task: 'Family Law Task',
          amount: '$500',
          status: 'paid',
          background: '#D1FAE5',
          color: '#059669',
          img: tableUser
        },
        {
          id: '#08980',
          lawfirm: 'Basant Osama',
          task: 'Family Law Task',
          amount: '$500',
          status: 'overdue',
          background: '#FEE2E2',
          color: '#F87171',
          img: user2
        },
      ]
    })

  } 



  // Customizable Area End
}
