import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { user1,user2,user3 } from "./assets";
interface Details {
  Title: string;
  Data: string;
  Imag: string;
}
interface Task {
  dotColor:string,
  spantext:string,
  title:string,
  data:string,
  backgroundData:string,
  colordata:string,
  checkedFirst:boolean,
  checkedSecond:boolean
}
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  details: Details;
  DetailsUser: Details[]
  task:Task;
  TaskAll:Task[]
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      details: {
        Title: "",
        Data: "",
        Imag: ""
      },
      DetailsUser: [],
      task:{
        dotColor:"",
        spantext:"",
        title:"",
        data:"",
        backgroundData:"",
        colordata:"",
        checkedFirst:false,
        checkedSecond:false
      },
      TaskAll:[]
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    this.setState({
      DetailsUser: [
    {
      Title:"Megan Stone sent you an enquiry",
      Data:"Megan: The evidence has been double checked and will be filed today.",
      Imag:`${user1}`
    },
    {
      Title:"Maleficent Lynch sent you an enquiry",
      Data:"Maleficent: The evidence has been double checked and will be filed today.",
      Imag:`${user2}`
    },
    {
      Title:"Dominic Stuart sent you an enquiry",
      Data:"Dominic: The evidence has been double checked and will be filed today.",
      Imag:`${user3}`
    }],
    TaskAll:[
      {
        dotColor:"#355EE7",
        spantext:"12",
        title:"In Progress",
        data:"High",
        backgroundData:"#FEE2E2",
        colordata:"#DC2626",
        checkedFirst:true,
        checkedSecond:false
      },
      {
        dotColor:"#8C8C8C",
        spantext:"5",
        title:"Overdue",
        data:"Normal",
        backgroundData:"#FEF3C7",
        colordata:"#D97706",
        checkedFirst:true,
        checkedSecond:false
      },{
        dotColor:"#059669",
        spantext:"20",
        title:"Completed",
        data:"Low",
        backgroundData:"#F5F5F5",
        colordata:"#8C8C8C",
        checkedFirst:true,
        checkedSecond:true
      },{
        dotColor:"#F87171",
        spantext:"0",
        title:"Declined",
        data:"Low",
        backgroundData:"#F5F5F5",
        colordata:"#8C8C8C",
        checkedFirst:false,
        checkedSecond:false
      },

    ]
  })
    
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    
    // Customizable Area End
  }
  // Customizable Area Start
  handleLawfirm = () => {
    const Notimessage: Message  = new Message(getName(MessageEnum.NavigationMessage));
    Notimessage.addData(getName(MessageEnum.NavigationTargetMessage),
      "lawfirmManagement");

    Notimessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(Notimessage);
  }
  handleLawyer = () => {
    const Notimessage2: Message
      = new Message(getName(MessageEnum.NavigationMessage));
    Notimessage2.addData(getName(MessageEnum.NavigationTargetMessage),"AdminLawyerManagement");

    Notimessage2.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);  this.send(Notimessage2);
  }
  handleService = () => {
    const Notimessage1: Message = new Message(getName(MessageEnum.NavigationMessage));
    Notimessage1.addData(getName(MessageEnum.NavigationTargetMessage),
      "ServicesManagement");

    Notimessage1.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);
    this.send(Notimessage1);
  }
  // Customizable Area End
}
