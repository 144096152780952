Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings2";
exports.labelBodyText = "settings2 Body";
exports.deleteUserApi="account_block/accounts/destroy_account"
exports.apiMethodTypeAddDetail="PATCH";
exports.apiDeleteMethod="DELETE";
exports.getApiMethod = 'GET';
exports.termsConditionEndPoint = "bx_block_terms_and_conditions/terms_and_conditions"
exports.privacyPolicyEndPoint = "bx_block_terms_and_conditions/privacy_policies"
exports.getUserDataEndPoint ="account_block/accounts/"
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End